import { updateUserDetails } from 'api/auth'
import { createGenesysAgentCallback } from 'api/supportAssistant'
import { MappedRequestAgentCallbackResult, RequestAgentCallbackResult } from './Modals/RequestAgentCallbackModal'

interface CreateRequestAgentCallbackData {
  formData: RequestAgentCallbackResult;
  account: App.AuthAccount;
  chatId?: string;
}

function mapFormData({ formData, account, chatId }: CreateRequestAgentCallbackData) {
  const customerPhone = `${account.phonePrefix || ''} ${account.phone || ''}`.trim()

  return {
    chatId,
    customerId: account.memberId ?? '',
    customerPhone,
    type: formData.type,
    isImmediateCallback: formData.callbackTime === 'As soon as possible',
    customer: {
      firstName: formData.firstName ?? account.givenName,
      lastName: formData.lastName ?? account.surname,
      email: formData.email ?? account.email,
      phone: `${formData.phone.prefix ?? ''} ${formData.phone.phone ?? ''}`,
      message: formData.message,
      destination: formData.destination,
      numberOfTravellers: formData.numberOfTravellers,
      travelDates: formData.travelDates,
      countryCode: account.countryCode,
      callbackDate: formData.callbackDate,
      callbackTime: formData.callbackTime,
      timezone: formData.timezone,
    },
  }
}
export async function createRequestAgentCallback(
  formData: RequestAgentCallbackResult,
  account: App.AuthAccount,
  chatId?: string,
): Promise<MappedRequestAgentCallbackResult> {
  const mappedRequestAgentCallbackFormData = mapFormData({
    formData,
    account,
    chatId,
  })

  const results = await createGenesysAgentCallback(mappedRequestAgentCallbackFormData)

  // If the user met the conditions for their phone number to be updated in svc-support
  if (results.data.updatePhoneNumber) {
    updateUserDetails({
      phone: formData.phone.phone,
      phonePrefix: formData.phone.prefix,
    })
  }

  return results.data
}
