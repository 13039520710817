import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import Group from 'components/utils/Group'

export function RequestAgentCallbackErrorModal() {
  return (
    <ModalBase>
      <form>
        <ModalHeader title="Callback request error" />
        <ModalBody>
          <ModalContent>
            <VerticalSpacer gap={20}>
              <Group direction="vertical" gap={20}>
                <BodyTextBlock variant="large">
                  There was an error scheduling your callback.
                </BodyTextBlock>
              </Group>
            </VerticalSpacer>
          </ModalContent>
        </ModalBody>
        <ModalFooter primaryActionProps={{ children: 'Close' }}/>
      </form>
    </ModalBase>
  )
}
