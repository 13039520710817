import React, { FormEventHandler, useCallback, useEffect, useState } from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import Group from 'components/utils/Group'
import TextArea from 'components/Common/Form/Input/TextArea'
import { formToObject } from 'lib/forms/formToObject'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { connect } from 'react-redux'
import TextInput from 'components/Common/Form/Input/TextInput'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import AgentCallbackRequestDateTimeInput from '../RequestAgentCallbackDateTimeInput'
import { parseSelectedNumberOfTravellersToFormText, parseSelectedTravelDatesToFormText } from '../RequestAgentCallbackUtils'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import AccountAccessModalContent from 'components/Account/AccountAccess/AccountAccessModalContent'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn as isLoggedInSelector } from 'selectors/accountSelectors'
import { createRequestAgentCallback } from '../CreateRequestAgentCallback'
import { RequestAgentCallbackConfirmationModal } from './RequestAgentCallbackConfirmationModal'
import { RequestAgentCallbackErrorModal } from './RequestAgentCallbackErrorModal'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { RequestAgentCallbackWarningModal } from './RequestAgentCallbackWarningModal'
import * as Analytics from 'analytics/analytics'
import { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'

const callbackTypeToAnalyticsSubject: Utils.PartialRecord<App.GenesysAgentCallbackType, string> = {
  SALES: 'request_agent_callback_sales',
  SUPPORT: 'request_agent_callback_support',
}

export interface RequestAgentCallbackResult {
  firstName: string;
  lastName: string;
  type: App.GenesysAgentCallbackType;
  email: string;
  phone: { phone: string, prefix: string };
  destination: string;
  travelDates: string;
  message: string;
  numberOfTravellers: string;
  callbackDate: Date;
  callbackTime: string;
  timezone: string;
}

export interface MappedRequestAgentCallbackResult {
  chatId: string | undefined;
  customerId: string;
  customerPhone: string;
  type: App.GenesysAgentCallbackType;
  isImmediateCallback: boolean;
  updatePhoneNumber: boolean;
  customerHasRecentlyScheduledCallback: boolean;
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message?: string;
    destination?: string;
    numberOfTravellers?: string;
    travelDates?: string;
    countryCode?: string;
    callbackDate?: Date;
    callbackTime?: string;
    timezone?: string;
  };
}

interface MappedStateProps {
  loggedIn: boolean;
  account: App.AuthAccount;
  filters?: App.OfferListFilters;
}

interface Props {
  type: App.GenesysAgentCallbackType;
  chatId?: string;
  isLESATouchpoint?: boolean;
  filters?: App.OfferListFilters;
  location?: string;
  dismissable?: boolean;
  initialMode?: App.UiAccountModalMode;
  resetPasswordToken?: string;
  hotelOfferDetails?: App.AccountModalOfferDetails;
  defaultEmail?: string;
  callbackPath?: string;
}

function RequestAgentCallbackModal(props: MappedStateProps & Props) {
  const {
    initialMode = 'requestAgentCallbackLogin',
    resetPasswordToken,
    hotelOfferDetails,
    dismissable,
    defaultEmail,
    callbackPath,
    loggedIn,
    account,
    type,
    chatId,
    isLESATouchpoint,
    filters,
    location,
  } = props

  useEffect(() => {
    Analytics.trackClientEvent({
      action: 'request_agent_callback_form_displayed',
      subject: callbackTypeToAnalyticsSubject[type],
      category: AnalyticsPage.requestAgentCallback,
      type: 'interaction',
    })
  }, [type])

  const [mode, setMode] = useState<App.UiAccountModalMode>(initialMode)
  const loading = useAppSelector(state => state.auth.processing)

  const modalElement = useModalElementContext<MappedRequestAgentCallbackResult | undefined>()

  const [numberOfTravellers, setNumberOfTravellers] = useState(parseSelectedNumberOfTravellersToFormText(filters ?? {}) ?? '')
  const [travelDates, setTravelDates] = useState(parseSelectedTravelDatesToFormText(filters ?? {}) ?? 'Anytime')
  const [results, setResults] = useState<MappedRequestAgentCallbackResult | null>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (filters) {
      setNumberOfTravellers(parseSelectedNumberOfTravellersToFormText(filters) || '')
      setTravelDates(parseSelectedTravelDatesToFormText(filters) || 'Anytime')
    }
  }, [filters])

  const handleNumberOfTravellersChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setNumberOfTravellers(e.target.value)
  }

  const handleTravelDatesChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTravelDates(e.target.value)
  }

  const MAX_LENGTH = 100
  const MAX_LENGTH_COMMENTS = 500

  const showCallbackForm: boolean = !showConfirmationModal && !showWarningModal && !showErrorModal

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(async(e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const requestAgentCallbackFormData = formToObject<RequestAgentCallbackResult>(e.currentTarget)

    try {
      const results: MappedRequestAgentCallbackResult = await createRequestAgentCallback(requestAgentCallbackFormData, account, chatId)

      setResults(results)
      if (isLESATouchpoint) {
        modalElement.resolve(results)
      }
      else if (results.customerHasRecentlyScheduledCallback) {
        setShowWarningModal(true)
      }
      else {
        setShowConfirmationModal(true)
      }
    } catch {
      setShowErrorModal(true)
    }
    finally {
      setIsSubmitting(false)
    }
  }, [account, chatId, isLESATouchpoint, modalElement])

  return (
    <ModalBase>
      <LoadingIndicator visible={loading} floating />
      {!loggedIn &&
        <AccountAccessModalContent
          mode={mode}
          defaultEmail={defaultEmail}
          onModeChange={setMode}
          resetPasswordToken={resetPasswordToken}
          hotelOfferDetails={hotelOfferDetails}
          callbackPath={callbackPath}
          dismissable={dismissable}
        />
        }
      {loggedIn && showCallbackForm &&
        <form onSubmit={handleSubmit}>
          <ModalHeader title="Request a callback"/>
          <ModalBody>
            <ModalContent>
              <VerticalSpacer gap={20}>
                {(type === 'HOTELS_AND_VILLAS' || type === 'TOURS') && (
                  <BodyTextBlock variant="large">
                    {`Hi ${account.givenName}, you are entitled to a personalised concierge call to assist with your booking.
    Share your contact details, and our 24/7 concierge team will get back to you as soon as possible.`}
                  </BodyTextBlock>
                )}

                {(type === 'LESA' || type === 'SUPPORT' || type === 'SALES') && (
                  <BodyTextBlock variant="large">
                    Share your details with us, and our {type === 'SALES' ? 'sales' : 'support'} team will get back to you as soon as possible.
                  </BodyTextBlock>
                )}
                <Group direction="vertical" gap={8}>
                  {(type === 'LESA' || type === 'SUPPORT' || type === 'SALES') && (
                    <Group direction="horizontal" gap={8} horizontalAlign="stretch">
                      <TextInput
                        name="firstName"
                        defaultValue={account.givenName}
                        invalidErrorMessage="Invalid characters. Please use only letters and spaces"
                        label="First name *"
                        maxLength={MAX_LENGTH}
                        minLength={2}
                        pattern="^[A-Za-z ]+$"
                        placeholder="Your first name"
                        required
                        requiredErrorMessage="First name is required"
                      />
                      <TextInput
                        name="lastName"
                        defaultValue={account.surname}
                        invalidErrorMessage="Invalid characters. Please use only letters and spaces"
                        label="Last name *"
                        maxLength={MAX_LENGTH}
                        minLength={2}
                        pattern="^[A-Za-z ]+$"
                        placeholder="Your last name"
                        required
                        requiredErrorMessage="Last name is required"
                      />
                    </Group>
                  )}
                  <AgentCallbackRequestDateTimeInput type={type}/>
                  <PhoneInput
                    defaultPhoneNumber={account.phone}
                    defaultPhonePrefix={account.phonePrefix}
                    label="Mobile number"
                    name="phone"
                    placeholder="e.g. 412 345 678"
                    required
                    requiredErrorMessage="Mobile number is required"
                  />
                  {(type === 'HOTELS_AND_VILLAS' || type === 'TOURS') && (
                    <>
                      <TextInput
                        name="destination"
                        defaultValue={location}
                        invalidErrorMessage="Invalid characters. Please use only letters and spaces"
                        label="Where do you plan to go?"
                        maxLength={MAX_LENGTH}
                        minLength={2}
                        autoComplete="off"
                        pattern="^[A-Za-z ]+$"
                        placeholder="The destination you would like to travel to"
                        required
                        requiredErrorMessage="Destination is required"
                      />
                      {type === 'TOURS' &&
                        <TextInput
                        name="travelDates"
                        label="When are you looking to travel?"
                        maxLength={MAX_LENGTH}
                        minLength={2}
                        autoComplete="off"
                        placeholder="The dates you would like to travel on"
                        required
                        requiredErrorMessage="Dates are required"
                      />
                      }
                      {type === 'HOTELS_AND_VILLAS' &&
                        <TextInput
                        name="travelDates"
                        value={travelDates}
                        onChange={handleTravelDatesChange}
                        label="When are you looking to travel?"
                        maxLength={MAX_LENGTH}
                        minLength={2}
                        autoComplete="off"
                        placeholder="The dates you would like to travel on"
                        required
                        startIcon={<LineCalendarIcon />}
                        requiredErrorMessage="Dates are required"
                      />
                      }
                      {type === 'HOTELS_AND_VILLAS' && (
                        <TextInput
                        name="numberOfTravellers"
                        value={numberOfTravellers}
                        onChange={handleNumberOfTravellersChange}
                        label="How many people will you be travelling with?"
                        maxLength={MAX_LENGTH}
                        minLength={1}
                        autoComplete="off"
                        placeholder="The number of people you will be travelling with"
                        required
                        requiredErrorMessage="Number of people you are travelling with is required"
                      />
                      )}
                    </>
                  )}
                  {(type === 'LESA' || type === 'SUPPORT' || type === 'SALES') && (
                    <>
                      <TextInput
                        invalidErrorMessage="Invalid email address"
                        defaultValue={account.email}
                        label="Email address *"
                        maxLength={MAX_LENGTH}
                        name="email"
                        placeholder="Enter your business email"
                        required
                        requiredErrorMessage="Email address is required"
                        type="email"
                      />
                      <TextArea
                        label="Message"
                        maxLength={MAX_LENGTH_COMMENTS}
                        name="message"
                        placeholder="e.g. I have a payment issue"
                      />
                    </>
                  )}
                  {(type === 'HOTELS_AND_VILLAS' || type === 'TOURS') && (
                    <TextArea
                    label="Any other questions or comments?"
                    maxLength={MAX_LENGTH_COMMENTS}
                    name="message"
                    placeholder="e.g. Do I need a vaccine?"
                  />
                  )}
                  <HiddenInput
                    name="type"
                    value={type}
                  />
                </Group>
              </VerticalSpacer>
            </ModalContent>
          </ModalBody>
          {isSubmitting && <LoadingIndicator floating />}
          <ModalFooter
            primaryActionProps={{ children: 'Submit Request', type: 'submit', disabled: isSubmitting }}
          />
        </form>
      }
      {showConfirmationModal && results && <RequestAgentCallbackConfirmationModal customer={results.customer} />}
      {showWarningModal && <RequestAgentCallbackWarningModal />}
      {showErrorModal && <RequestAgentCallbackErrorModal />}
    </ModalBase>
  )
}

export default connect<MappedStateProps, undefined, Partial<Props>, App.State>(
  (state) => ({
    loggedIn: isLoggedInSelector(state),
    account: state.auth.account,
  }),
)(RequestAgentCallbackModal)
